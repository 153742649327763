(function () {

  // Hides the expand elements inside the research table td's. 
  // Currently only in use in the State Voting & Election Laws Dashboard.
  function researchTableExpandclick(e) {
    let tr = e.target.closest('tr');
    tr.classList.toggle('expanded');
  }

  function attachExpandButtonClicks() {
    let expandButtons = document.querySelectorAll(`body[class^='research-'] .expand-button`);
    expandButtons.forEach(button => {
      button.onclick = researchTableExpandclick;
    });

    let collapseButtons = document.querySelectorAll(`body[class^='research-'] .collapse-button`);
    collapseButtons.forEach(button => {
      button.onclick = researchTableExpandclick;
    });
  }

  document.addEventListener('turbolinks:load', attachExpandButtonClicks);
})()
