// https://developers.google.com/maps/documentation/javascript/custom-markers - custom markers and bootstrapping the map
// https://developers.google.com/maps/documentation/javascript/styling - styling the map
window.createGoogleMap = function() {
  var headQuartersPosition = new google.maps.LatLng(38.89431839949652, -77.06907109005104);
  var mobile = window.matchMedia('screen and (max-width: 767px)')

  var colors = {
    pink: '#E5096E',
    white: '#FFFFFF',
    gray: '#E5E4E5',
  };

  var map = new google.maps.Map(
    document.getElementById('Map-Container'),
    {
      center: new google.maps.LatLng(mobile.matches ? 38.85 : 38.9051659, -77.045351),
      zoom: 11,
      // https://developers.google.com/maps/documentation/javascript/examples/style-array -- night mode
      styles: [
        {elementType: 'labels.text.stroke', stylers: [{color: colors.white}]},
        {elementType: 'labels.text.fill', stylers: [{color: colors.pink}]},
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: colors.white}]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: colors.white}]
        },
        {
          featureType: 'poi',
          elementType: 'geometry',
          stylers: [{color: colors.gray}]
        }
      ]
    }
  );

  if (!window.multiStateLogoUrl.match(/^http/)) {
    // absolute url when webpack dev server is running, public not necessary when deployed
    window.multiStateLogoUrl = window.location.origin + '/' + window.multiStateLogoUrl.replace('public/', '');
  }

  var directionsUrl = 'https://www.google.com/maps/dir//1000+Wilson+Blvd,+Arlington,+VA+22209/@38.8942647,-77.1105934,13z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x89b7b6598c582815:0xda06907d98789bf0!2m2!1d-77.069308!2d38.8942052!3e2';
  var directionsWindow = new google.maps.InfoWindow({
    content: '<a target="_blank" href="' + directionsUrl + '">Click for directions</a>'
  });

  // create custom marker for the office
  var pin = new google.maps.Marker({
    position: headQuartersPosition,
    map: map
  });
  var multiStateIcon = new google.maps.Marker({
    position: headQuartersPosition,
    icon: window.multiStateLogoUrl,
    map: map
  });

  directionsWindow._msaOpenStatus = false;
  
  multiStateIcon.addListener('click', function() {
    if (directionsWindow._msaOpenStatus) {
      window.open(directionsUrl);
    } else {
      directionsWindow.open(map, multiStateIcon);
    }
    directionsWindow._msaOpenStatus = !directionsWindow._msaOpenStatus;
  });

}