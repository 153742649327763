(function() {
  if (!Array.from) {
    Array.from = function(fakeArray) {
      var realArray = [];
      for (var i = 0; i < fakeArray.length; i++) {
        realArray.push(fakeArray[i]);
      }
      return realArray;
    }
  }
  if (!Array.includes) {
    Array.prototype.includes = function(item) {
      return this.indexOf(item) >= 0;
    }
  }
  if (!Array.forEach) {
    Array.forEach = function(callback, context) {
      for (var i = 0; i < this.length; i++) {
        context ? callback.call(context, this[i], i, this) : callback(this[i], i, this);
      }
    }
  }
})()