import Modal from '../components/modal';

(function() {

  function successModal() {
    new Modal({
      width: 400,
      header: 'Thanks!',
      text: 'We will be in touch with you soon. In the meantime, take a look at the <a href="/insider">MultiState Insider</a>.'
    })
  }

  function checkForSuccess() {
    if (window.location.href.indexOf('/contact?success=true') >= 0) successModal();
  }

  function contactTestOnly() {
    const engineering = document.getElementById('engineering')
    engineering.value = 'yes';
  }

  document.addEventListener('turbolinks:load', checkForSuccess);
  global.contactTestOnly = contactTestOnly;
})()