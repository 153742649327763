var articleId = 'Article';
var landingPageId = 'LandingPage';

function findArticle() {
  if (document.getElementById(articleId)) {
    createImageLinks(articleId);
  } else if (document.getElementById(landingPageId)) {
    createImageLinks(landingPageId);
  }
}

function createImageLinks(containerId) {
  var images = Array.from(document.querySelectorAll('#' + containerId + ' .content img'));
  images.forEach(function(image, index) { wrapWithLink(image, index, containerId === landingPageId); });
}

function wrapWithLink(image, index, addDownload) {
  if (!isInsideLink(image, 3)) {
    var a = document.createElement('a');
    a.href = image.src;
    a.target = '_blank';
    a.title = 'Click to open full size image';
    image.parentNode.insertBefore(a, image);
    a.appendChild(image);
    if (addDownload) {
      var downloadButton = document.createElement('a');
      downloadButton.className = 'landing-page-download-button';

      // Google drawings *sometimes* have query params present in the image url
      var delimiter = image.src.includes('?') ? '&' : '?';
      var downloadUrl = image.src + delimiter + 'imagedownload=true';
      downloadButton.setAttribute('data-download', downloadUrl);

      // Google drawings *somtimes* lack a file extension.
      // Defaulting to .jpg in those instances.
      var extension = image.src.split('.').pop();
      if (!extension || extension.length > 4) {
        extension = `jpg`;
      }
      fileName = `MultiState ${window.location.href.split('/').pop()}${index > 0 ? `(${index})` : ''}.${extension}`;
      downloadButton.setAttribute('data-filename', fileName);

      downloadButton.innerHTML = 'download image';
      downloadButton.addEventListener('click', forceDownload)
      a.insertAdjacentElement('afterend', downloadButton);
    }
  }
}

// https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click
function forceDownload(e) {
  url = e.target.getAttribute('data-download');
  fileName = e.target.getAttribute('data-filename');
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function(){
    var urlCreator = window.URL || window.webkitURL;
    var imageUrl = urlCreator.createObjectURL(this.response);
    var tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = fileName;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  }
  xhr.send();
}

function isInsideLink(child, countDown) {
  if (countDown <= 0) return false;
  return (child.parentNode.tagName || '').toUpperCase() === 'A' || isInsideLink(child, --countDown);
}

window.addEventListener('turbolinks:load', findArticle);