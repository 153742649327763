import ToolTip from '../components/tooltip';

function clickShareLinkButton(e) {
  var url = window.location.href;

  var textArea = document.createElement('textarea');
  textArea.style.opacity = 0;
  textArea.style.maxHeight = '0px';
  textArea.style.maxWidth = '0px';
  textArea.value = url;

  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  textArea.remove();

  new ToolTip({
    content: 'Copied to clipboard!',
    x: e.offsetX,
    y: e.offsetY,
    duration: 1500,
    host: e.target
  });
  return false;
}

function attachShareLinkListener(shareLinkButton) {
  shareLinkButton = shareLinkButton || document.getElementById('ShareLinkButton');
  if (shareLinkButton) {
    shareLinkButton.onclick = clickShareLinkButton;
  }
}

var articleSelector = '#Article .content-and-authors';
var landingPageSelector = '#LandingPage .container.fullscreen';
var findBottomTimer;
var floatingShare;
var actualShare;
var scrollBreakPoint
var scrollBottom;

function resetState() {
  findBottomTimer = undefined;
  floatingShare = undefined;
  actualShare = undefined;
  scrollBreakPoint = undefined;
  scrollBottom = undefined;
}

function findFloatingContainer() {
  return document.querySelector(articleSelector) || document.querySelector(landingPageSelector);
}

function attachShareMover() {
  if (desktop_query.matches) {
    var floaterContainer = findFloatingContainer();
    if (floaterContainer) createFloater(floaterContainer);
  }
}

function createFloater(floaterContainer) {
  actualShare = document.querySelector('.share');
  floatingShare = actualShare.cloneNode(true);
  var floatingLinkShare = floatingShare.querySelector('#ShareLinkButton')
  if (floatingLinkShare) {
    floatingLinkShare.id = 'FloatingShareLinkButton';
    attachShareLinkListener(floatingLinkShare);
  }
  floatingShare.className += ' floater';
  var shareBox = actualShare.getBoundingClientRect();
  boundFloatingLeft(undefined, shareBox);
  scrollBreakPoint = shareBox.top + window.pageYOffset;
  findArticleBottom(floaterContainer);
  window.addEventListener('scroll', shareScrollHandler);
  shareScrollHandler();
}

function boundFloatingLeft(_empty, shareBox) {
  if (!shareBox && actualShare) {
    shareBox = actualShare.getBoundingClientRect();
  }
  if (shareBox && floatingShare) {
    floatingShare.style.left = shareBox.left + 'px';
  }
}

function findArticleBottom(floaterContainer) {
  if (!floaterContainer) { floaterContainer = findFloatingContainer(); }
  if (floaterContainer) {
    var articleBox = floaterContainer.getBoundingClientRect();
    scrollBottom = articleBox.bottom - 325 + window.pageYOffset;
  }
}

function shareScrollHandler() {
  if (scrollBreakPoint !== undefined) {
    toggleShareFloater(window.pageYOffset >= scrollBreakPoint);
  }
}

function showShareFloater() {
  if (!floatingShare) return;

  if (!floatingShare.parentElement) {
    document.body.appendChild(floatingShare);
    actualShare.className += ' invisible';
  }
  var translateY = scrollBottom - window.pageYOffset;
  if (translateY < 0) {
    floatingShare.style.transform = 'translateY(' + translateY + 'px)';
  } else {
    floatingShare.style.transform = 'none';
  }
}

function hideShareFloater() {
  if (floatingShare && floatingShare.parentElement) {
    floatingShare.remove();
    if (actualShare) actualShare.className = actualShare.className.replace('invisible', '').trim();
  }
}

function toggleShareFloater(showFloater) {
  showFloater ? showShareFloater() : hideShareFloater();
}

function scheduleBottomPlotter(timer) {
  findBottomTimer = setTimeout(function() {
    findArticleBottom();
    shareScrollHandler();
    if (timer < 8000) { scheduleBottomPlotter(timer * 2); }
  }, timer);

}

function setUpShareLinks() {
  attachShareLinkListener();
  attachShareMover();
  scheduleBottomPlotter(1000);
}

function cleanShareLinks() {
  toggleShareFloater(false);
  window.removeEventListener('scroll', shareScrollHandler);
  window.removeEventListener('resize', boundFloatingLeft);
  clearTimeout(findBottomTimer);
  resetState();
}

document.addEventListener('turbolinks:load', setUpShareLinks);
document.addEventListener('turbolinks:before-cache', cleanShareLinks);
window.addEventListener('resize', boundFloatingLeft);