import Modal from '../components/modal';

(function() {

  var formIds = ['Newsletter', 'NewsletterSidebar']

  function submit(load) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', window.location.origin + '/newsletter');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function() {
      var response = JSON.parse(xhr.response);
      var options = { width: 400 };
      if (xhr.status === 200 & response.status === 'success') {
        options.text = 'You are now subscribed to the MultiState Newsletter. Check your inbox for a confirmation email.'
      } else if (response.error && response.error.indexOf('is already a list member') >= 0) {
        options.header = 'Good news!';
        options.text = 'You are already subscribed to the MultiState Newsletter.';
      } else {
        options.text = 'There was an error adding ' + load.email + ' to the MultiState newsletter.';
        options.success = false;
        options.error = response.error;
      }
      new Modal(options);
    }
    xhr.send(JSON.stringify(load));
  }

  function submitForm(e) {
    e.preventDefault();
    var email = (e.target.querySelector('input[type="email"]') || { value: null }).value;
    var rope = (e.target.querySelector('[aria-hidden="true"]') || { value: null }).value;
    var token = (e.target.querySelector('input[name="authenticity_token"]') || { value: null}).value;
    if (email) {
      submit({
        email: email,
        rope: rope,
        authenticity_token: token
      });
    }
  }

  function attachFormHandlers() {
    formIds.forEach(function(id) {
      var newsLetter = document.getElementById(id);
      if (newsLetter) newsLetter.addEventListener('submit', submitForm);
    });
  }


  document.addEventListener('turbolinks:load', attachFormHandlers);
})()