function handleNavigationScroll() {
  var auto = 'auto';
  var manual = 'manual';
  var turboEvent = false;

  function setS(value) {
    window.history.scrollRestoration = value;
  }

  document.addEventListener('turbolinks:before-visit', function(e) {
    setS(manual);
    turboEvent = true;
  });

  window.addEventListener('beforeunload', function(e) {
    if (!turboEvent) {
      setS(auto);
    }
    turboEvent = false;
  });
}

// https://github.com/turbolinks/turbolinks/issues/181#issuecomment-423448708
if ('scrollRestoration' in window.history) {
  handleNavigationScroll();
}


(function() {
  function smoothAnchorScroll () {
    // https://stackoverflow.com/questions/7717527/smooth-scrolling-when-clicking-an-anchor-link
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  }

  document.addEventListener('turbolinks:load', smoothAnchorScroll);
})()
