function findForm(elem) {
  if (elem.tagName === 'FORM') return elem;
  return elem.parentElement ? findForm(elem.parentElement) : null;
}

function attachSearchSelectListener() {
  var searchSelect = document.getElementById('Article-Search-Select');
  if (searchSelect) {
    searchSelect.addEventListener('change', function(e) {
      var form = findForm(this);
      if (form) form.submit();
    });
  }
}

window.addEventListener('turbolinks:load', attachSearchSelectListener);