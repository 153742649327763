export default class Modal {
  /**
   * @typedef {Object} ModalOptions
   * @property {function} clickOk - click handler for modal button (default: close modal)
   * @property {number} width - maxWidth(px) of modal
   * @property {string} text - innerHTML of modal main paragraph
   * @property {boolean} success - success or failure state (default: true)
   * @property {string} header - Modal title (default: 'Success!' and 'Whoops' for success and failure state, respectively)
   * @property {string} error - innerHTML of error paragraph in failure state
   */

   /**
    * @param {ModalOptions} options - define the Modal
    */
  constructor(options) {
    this.close = this.close.bind(this);
    this.createBackdrop();
    this.createContents(options);
    this.mount();
  }

  createAcknowledgeButton(options) {
    this.button = document.createElement('button');
    this.button.className = 'acknowledge';
    this.button.innerHTML = 'OK';
    this.button.addEventListener('click', options.clickOk || this.close);
  }
  
  close(e) {
    e.stopPropagation();
    this.backdrop.parentNode.removeChild(this.backdrop);
  }
  
  createBackdrop() {
    this.backdrop = document.createElement('div');
    this.backdrop.className = 'custom-modal-backdrop';
    this.backdrop.addEventListener('click', this.close);
  }
  
  
  createContents(options) {
    var container = document.createElement('div');
    container.className = 'modal-body';
    container.addEventListener('click', function(e) { e.stopPropagation(); });
    if (options.width) container.style.maxWidth = options.width + 'px';
    var header = document.createElement('h2');
    var content = document.createElement('p');
    content.className = 'text';
    container.appendChild(header);
    content.innerHTML = options.text;
    container.appendChild(content);
    if (!options.hasOwnProperty('success') || options.success) {
      header.className = 'header success';
      header.innerHTML = options.header || 'Success!';
    } else {
      header.className = 'header failure';
      header.innerHTML = options.header || 'Whoops';
      var error = document.createElement('p');
      error.className = 'error';
      error.innerHTML = options.error;
      container.appendChild(error);
    }
    this.createAcknowledgeButton(options);
    container.appendChild(this.button);

    this.backdrop.appendChild(container);
  }
  
  mount() {
    document.body.appendChild(this.backdrop);
    this.button.focus();
  }
}
