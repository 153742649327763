var globalTwitterVars = [
  '__twttrll',
  'twttr',
  '__twttr'
];

function cleanTwitter() {
  globalTwitterVars.forEach(function(k) {
    if (window[k]) {
      window[k] = null;
    }
  });
  Array.from(document.head.querySelectorAll('script[src]')).forEach(function(s) {
    if (s.getAttribute('src').match('twitter.com')) {
      s.remove();
    }
  });
}

document.addEventListener('turbolinks:before-visit', cleanTwitter)