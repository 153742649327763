import { Controller } from 'stimulus';

export class SliderController extends Controller {

  initialize() {
    this.page = this.page;
  }

  hideOrShowTargets(slider, value) {
    Array.from(slider.children).forEach((child, i) => {
      if (i + 1 === value) {
        Array.from(child.querySelectorAll('a')).forEach(link => {
          link.removeAttribute('tabindex');
          link.setAttribute('aria-hidden', 'false');
        });
      } else {
        Array.from(child.querySelectorAll('a')).forEach(link => {
          link.setAttribute('tabindex', '-1');
          link.setAttribute('aria-hidden', 'true');
        });
      }
    });
  }

  setArrowState(start, end) {
    if (this.page == 1) {
      start.classList.add('disabled');
      start.setAttribute('tabindex', '-1');
    } else {
      start.classList.remove('disabled');
      start.setAttribute('tabindex', '0');
    }
    if (this.page == this.total) {
      end.classList.add('disabled');
      end.setAttribute('tabindex', '-1');
    } else {
      end.classList.remove('disabled');
      end.setAttribute('tabindex', '0');
    }
  }

  previous() {
    if (this.page > 1) this.page--
  }

  next() {
    if (this.page < this.total) this.page++
  }

  previousKey(e) {
    if (e.key === 'Enter') this.previous();
  }

  nextKey(e) {
    if (e.key === 'Enter') this.next();
  }

  jump(event) {
    var difference = event.target.dataset.index - this.page;
    while (difference > 0) {
      this.next();
      --difference;
    }
    while (difference < 0) {
      this.previous();
      ++difference;
    }
  }
}