export default class ToolTip {
  /**
   * @typedef {Object} ToolTipOptions
   * @property {string} content - innerhtml of tooltip
   * @property {number} x - left position of tooltip
   * @property {number} y - top position of tooltip
   * @property {number} duration - amount of time(ms) tooltip is visible (default: 1000)
   * @property {HTMLElement} host - host element to which tooltip attaches (default: document.body)
   */

  /**
   * @param {ToolTipOptions} options - define the tooltip
   */
  constructor(options) {
    this.close = this.close.bind(this);
    this.createTooltip(options);
    this.scheduleClose(options.duration || 1000);
  }

  close() {
    this.domElement.remove();
    if (this.host) this.host.style.position = this.hostOriginalPosition;
  }

  /**
   * @param {ToolTipOptions} options - define the tooltip
   * @param {number} - duration the tooltip stays visible
   */
  createTooltip(options) {
    this.domElement = document.createElement('div');
    this.domElement.innerHTML = options.content;
    this.domElement.className = 'custom-tooltip';
    this.domElement.style.left = options.x + 'px';
    this.domElement.style.top = options.y + 'px';
    if (options.host) {
      this.host = options.host
      this.hostOriginalPosition = this.host.style.position;
      this.host.style.position = 'relative';
      this.host.appendChild(this.domElement);
    } else {
      document.body.appendChild(this.domElement);
    }
  }
  
  scheduleClose(duration) {
    this.domElement.style.animationDuration = (duration * 1.1) + 'ms';
    this.scheduledClose = setTimeout(this.close, duration);
  }
}