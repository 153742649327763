(function () {
  function departmentClick(e) {
    let department = e.target.getAttribute('data-department');

    // Hide/show career sections per sidebar department button click.
    let sections = document.querySelectorAll(`.careers`);
    sections.forEach(section => {
      if (department == 'all') {
        section.classList.remove('hide');
      } else if (section.getAttribute('data-department') != department) {
        section.classList.add('hide');
      } else {
        section.classList.remove('hide');
      }
    })

    // Toggle the department button active class.
    let buttons = document.querySelectorAll(`.department`);
    buttons.forEach(button => {
      if (button.getAttribute('data-department') == department) {
        button.classList.add('active');
      } else {
        button.classList.remove('active');
      }
    });

    // Update roles header text.
    rolesHeader = document.querySelector(`#RolesHeader`);
    rolesHeader.innerHTML = e.target.innerHTML.replace(/\s\(\d+\)/, ' Roles');
  }

  function attachDepartmentButtonClicks() {
    let buttons = document.querySelectorAll(`body[class^='pages-careers'] .department`);
    buttons.forEach(button => {
      button.onclick = departmentClick;
    });
  }

  document.addEventListener('turbolinks:load', attachDepartmentButtonClicks);
})()
