// https://github.com/rails/sprockets#sprockets-directives

import 'polyfill';
import './media_queries';
import './swiped_events';
import './share_links';
import './twitter';
import './google_map';
import './turbo_scroll';
import './clean_linked_data';
import './newsletter';
import './contact_form'
import './articles_search';
import './video';
import './article_image_links';
import './focus_search';
import './research';
import './careers';
import { SliderController } from './slider_controller';
import { Application, Controller } from 'stimulus';

window.stimulus = Application.start()

stimulus.register('header', class extends Controller {
  static get targets() {
    return ['pageGroup'];
  }

  toggle(e) {
    clearTimeout(this.scheduledToggle);
    if (this.element.className.indexOf('show') < 0 || e.type !== 'focusin') {
      this.element.classList.toggle('show');
      this.pageGroupTargets.forEach(function(pageGroup) {
        pageGroup.classList.remove('show');
      });
    }
  }

  scheduleToggle(e) {
    this.scheduledToggle = setTimeout(() => this.toggle(e), 100)
  }

  disconnect() {
    this.element.classList.remove('light');
    this.pageGroupTargets.forEach(function(target) {
      target.querySelector('.page-group-body').classList.remove('show');
      target.querySelector('.page-group-header').querySelector('.page').classList.remove('active');
    });
  }

  findGroupHeader(e) {
    if (e.type === 'mouseenter') {
      return e.target;
    } else if (e.type === 'focusin') {
      return e.currentTarget.parentNode.querySelector('.page-group-header > .page');
    }
  }

  groupClick(e) {
    if (!desktop_query.matches) this.toggleGroup(e.target);
  }

  toggleGroup(target, showOnly = false) {
    var className = ' ' + target.className + ' ';
    if (className.indexOf(' page-group ') >= 0) {
      showOnly ? target.classList.add('show') : target.classList.toggle('show');
    } else {
      this.toggleGroup(target.parentNode, showOnly);
    }
  }

  groupEnter(e) {
    if (desktop_query.matches) {
      this.element.classList.add('light');
      var header = this.findGroupHeader(e);
      if (header) {
        header.classList.add('active');
        header.parentNode.parentNode.querySelector('.page-group-body').classList.add('show');
      }
    } else {
      this.toggleGroup(e.target, true);
    }
  }

  groupLeave(e) {
    if (desktop_query.matches) {
      this.element.classList.remove('light');
      e.currentTarget.parentNode.querySelector('.page-group-header .page').classList.remove('active');
      e.currentTarget.classList.remove('show');
    } else {
      this.toggleGroup(e.target);
    }
  }  
})


stimulus.register('h-slider', class extends SliderController {
  static get targets() {
    return ['slider', 'page', 'total', 'left', 'right', 'tab', 'indicator']
  }

  get page() {
    return parseInt(this.pageTarget.innerHTML)
  }

  get total() {
    return parseInt(this.totalTarget.innerHTML)
  }

  set page(value) {
    this.pageTarget.innerHTML = value < 10 ? '0' + value : value
    var offset = -100;
    if (this.element.getAttribute('data-modifier')) {
      var offset = window._internet_explorer ? -107 : -100;
    }
    this.sliderTargets.forEach(slider => {
      slider.style.left = `${(this.page - 1) * offset}%`
      this.hideOrShowTargets(slider, value)
    });
    this.setArrowState(this.leftTarget, this.rightTarget);

    // used for .pages-about#Process
    this.tabTargets.forEach((tab, i) => {
      (i == this.page - 1) ? tab.classList.add('active') : tab.classList.remove('active')
    })
    if (this.hasIndicatorTarget) this.indicatorTarget.style.marginLeft = `${(this.page - 1) * 128}px`
  }
});


stimulus.register('v-slider', class extends SliderController {
  static get targets() {
    return ['slider', 'tabs', 'tab', 'up', 'down']
  }

  get page() {
    return parseInt(this.data.get('page'))
  }

  get total() {
    return parseInt(this.data.get('total'))
  }

  set page(value) {
    this.data.set('page', value)
    this.sliderTargets.forEach(slider => {
      slider.style.top = `${(this.page - 1) * -100}%`;
      this.hideOrShowTargets(slider, value)
    });
    this.setArrowState(this.upTarget, this.downTarget);
    this.tabsTarget.style.top = `${(this.page - 1) * -72}px`
    this.tabTargets.forEach((tab, i) => {
      (i == this.page - 1) ? tab.classList.add('active') : tab.classList.remove('active')
    })    
  }
});
